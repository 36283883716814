@mixin popInAnimationFrames {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  97% {
    opacity: 1;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes popInAnimation {
  @include popInAnimationFrames;
}
@keyframes popInAnimation {
  @include popInAnimationFrames;
}

@mixin popOutAnimationFrames {
  0% {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
@-webkit-keyframes popOutAnimation {
  @include popOutAnimationFrames;
}
@keyframes popOutAnimation {
  @include popOutAnimationFrames;
}
