@import "variables";
@import "animations";
@import "~@coreui/coreui/scss/variables/colors";
@import "~@coreui/coreui/scss/variables";
@import "~@coreui/coreui/scss/bootstrap/mixins";
@import "~spinkit/scss/spinners/8-circle";

html, body {
  overscroll-behavior: none;
}

body {
  background-color: #fff;

  @media print {
    font-family: $font-family-print;
  }
}

.header-fixed .app-body {
  margin-top: 0;
}

h1.cs-h1 {
  margin-bottom: 1rem;
  font-weight: normal;
  color: $color-bluegrey;
}

.clear {
  clear: both;
}

.btn-dark {
  background: $color-dark-blue;
  border-color: $color-dark-blue;
}

.tooltip-inner {
  background-color: $color-dark-blue;
}

.tooltip .arrow::before {
  border-bottom-color: $color-dark-blue !important;
}


// Sidebar
.sidebar {
  z-index: 1030 !important;

  .sidebar-header {
    padding: 0.12rem 1rem;
    overflow: hidden;
    text-align: left;

    .navbar-brand-minimized {
      display: none;
    }

    .sidebar-minimized & {
      display: block;

      .navbar-brand {
        padding-top: 0.3rem;
        padding-bottom: 0;

        .navbar-brand-full {
          display: none;
        }

        .navbar-brand-minimized {
          display: block;
          margin-left: -0.4rem;
        }
      }
    }
  }

  .nav-link {
    font-size: 1rem;

    .nav-icon {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0 1rem -5px 0;
      font-size: 1.125rem;
      color: $color-nav-icon;
      transition: all 0.25s;
    }

    &.active {
      background-color: $color-dark-blue;
    }
  }

  .sidebar-minimizer {
    z-index: 1020;
  }

  .sidebar-footer {
    @media (max-width: 991.98px) {
      margin-bottom: 50px;
    }
    background: none;
  }

  .collapse-text {
    position: absolute;
    bottom: 0;
    height: 50px;
    width: 100%;
    line-height: 50px;
    text-align: center;
    margin-left: -0.75rem;
    opacity: 0.3;
  }
}


// Header & Breadcrumbs
.app-header {
  &.navbar {
    transition: all 0.25s;
    margin-left: -$sidebar-width;
    padding-left: $sidebar-width + $sidebar-gutter-width;
    padding-right: 0.625rem;

    .sidebar-minimized & {
      margin-left: -$sidebar-minimized-width;
      padding-left: $sidebar-minimized-width + $sidebar-gutter-width;

      @media (max-width: 991.98px) {
        margin-left: 0;
        padding-left: $sidebar-gutter-width;
      }
    }

    @media (max-width: 991.98px) {
      margin-left: 0;
      padding-left: $sidebar-gutter-width;
    }

    .navbar-toggler[data-sidebar-toggler="true"] {
      margin-left: -0.75rem
    }

    .aside-toggler {
      margin-right: 0.375rem;
    }
  }

  .breadcrumb {
    border: 0;
    margin: 0;
    padding: 0;
    background: none;

    .breadcrumb-item {
      &::before, &.active {
        color: $body-text;
      }
    }
  }

  ul.user-menu {
    margin-right: 1.2rem;

    .nav-link {
      font-weight: bold;
    }

    .dropdown-item {
      .svg-inline--fa {
        margin-right: 0.5rem;
      }

      &:hover {
        .duoBlue {
          color: $color-brand-blue !important;
        }
      }
    }
  }

  a#scope-filter {
    padding: 0 10px;
    color: $gray-600;

    &:hover, &:focus {
      color: $gray-900;
    }
  }
}


// Scope/Filter picker
.user-scope-title {
  text-align: center;
}

.user-scope-picker {
  width: 275px;
  padding-top: 1.33rem;
  padding-bottom: 1rem;
  -webkit-box-shadow: 8px 8px 16px -2px rgba(0,0,0,0.25);
  -moz-box-shadow: 8px 8px 16px -2px rgba(0,0,0,0.25);
  box-shadow: 8px 8px 16px -2px rgba(0,0,0,0.25);

  .user-scope-row {
    .user-scope-icon {
      float: left;
      clear: both;
      line-height: 36px;
      padding-right: 0.75rem;
      margin-bottom: 0.5rem;
      color: $color-primary-blue;
      font-size: 1.33rem;

      .svg-inline--fa.fa-fw {
        width: 1em;
      }

      &.disabled {
        color: $gray-300;
      }
    }

    .user-scope-select {
      float: left;
      width: 85%;
      margin-bottom: 0.5rem;
    }
  }

  button.btn {
    width: 47%;
    margin-top: 1rem;

    &:last-child {
      float: right;
    }
  }

  .Select-menu-outer {
    z-index: 5;
  }
}


// Content
.content-wrapper {
  margin-top: 5rem;
}


// Shadowed Cards
.card.withShadow {
  border-color: #f2f2f2;
  -webkit-box-shadow: 8px 8px 16px -2px rgba(0,0,0,0.25);
  -moz-box-shadow: 8px 8px 16px -2px rgba(0,0,0,0.25);
  box-shadow: 8px 8px 16px -2px rgba(0,0,0,0.25);
}


// FontAwesome Icon Customization
.duoBlue {
  color: $color-dark-blue;
  --fa-secondary-color: #bbdff1;
  --fa-secondary-opacity: 1.0;
}

.duoGreen {
  color: $color-brand-green;
  --fa-secondary-color: $color-brand-green-highlight-wash;
  --fa-secondary-opacity: 1.0;
}


// Page-level Icon Navigation
.pagenav-icons {
  a {
    font-size: 1.1rem;
    color: $color-bluegrey;
    font-style: italic;
    margin-left: 1rem;

    &:hover {
      text-decoration: none;

      .duoBlue {
        color: $color-brand-blue !important;
      }
    }
  }
}


// General datatable styling
.view-datatable {
  .data-table-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: -1rem;

    h1 {
      flex-grow: 1;
      margin: 1rem 0 0 0;
    }

    & > div {
      margin: 1rem 0 0 0;
      display: flex;
      align-items: center;

      .custom-date-search {
        width: 185px;
      }

      .custom-searchbar {
        float: right;
        width: 450px;
        @include media-breakpoint-down(md) {
          width: 350px;
        }
      }

      .scope-filter {
        margin-right: 1rem;

        @media print {
          display: none;
        }
      }

      .search-label {
        margin-bottom: 0;
        width: 250px;

        @media print {
          display: none;
        }
      }

      .refresh-button {
        margin-right: 1rem;
        line-height: 35px;

        @media print {
          display: none;
        }
      }

      .extra-icon {
        margin-right: 2rem;
        line-height: 35px;

        @media print {
          display: none;
        }
      }
    }
  }

  .table th {
    border-top: 0;
    color: $color-bluegrey;
  }

  .row-actions-header {
    font-weight: normal;
    font-style: italic;
  }

  .row-actions {
    a {
      cursor: pointer;

      svg {
        font-size: 1.5rem;
      }
    }

    .dropdown {
      a {
        margin: 0 !important;

        svg {
          width: 1.66rem;
          text-align: center;
        }
      }

      &.show {
        .dropdown-menu {
          min-width: 8rem;
          max-width: 10rem;
          right: 10px;
          top: -30px !important;
          border: 0;
          border-radius: 0;
          -webkit-box-shadow: 8px 8px 16px -2px rgba(0,0,0,0.25);
          -moz-box-shadow: 8px 8px 16px -2px rgba(0,0,0,0.25);
          box-shadow: 8px 8px 16px -2px rgba(0,0,0,0.25);

          button.dropdown-item {
            line-height: 1.66rem;
            font-size: 1rem;
            font-style: italic;
            border: 0 !important;
            border-radius: 0 !important;

            a {
              color: $color-bluegrey;

              &:hover {
                text-decoration: none;
              }

              svg {
                margin: 0;
                font-size: 1.66rem;
                color: $color-dark-blue;

                &.fa-trash-alt {
                  color: $color-red;
                }
              }
            }
          }
        }
      }
    }
  }

  .react-bootstrap-table-pagination {
    @media print {
      display: none;
    }
  }

  ul.pagination {
    float: right;
  }

  .react-bootstrap-table-pagination-total {
    margin-left: 0.5rem;
    color: $color-bluegrey;
  }

  .rbt-sort {
    float: right;

    &.rbt-sort-no {
      color: $gray-300;
    }

    @media print {
      display: none;
    }
  }

  &.hover-cursor {
    tr {
      cursor: pointer;
    }
  }

  .text-xs {
    font-size: x-small;
  }
  
  .label-top-padding label.custom-control-label {
    padding-top: 0.2rem;
  }
}


// Datatable row highlights
body.aside-menu-lg-show {
  .view-datatable {
    .row-highlighted {
      td {
        background-color: white;
        position: relative;
        z-index: 1050;
        border: 0;
        padding-top: 13px;

        &:first-child {
          @include border-left-radius(8px);
        }

        .aside-table-gradient {
          visibility: visible;
          right: 420px;
          opacity: 1;
        }
      }
    }
  }
}

.aside-table-gradient {
  width: 80px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1080;
  visibility: hidden;
  opacity: 0;
  background: linear-gradient(90deg, rgba(255,255,255,0) 10%, rgba(255,255,255,1) 85%);
  transition: right 0.25s linear;
  pointer-events: none;
}


// Slideout Panel
.aside-menu {
  border: none;

  @at-root body.aside-menu-lg-show & {
    margin-right: 0;
  }

  @at-root body.view-aside-menu & {
    padding-top: 105px;
    bottom: 50px;
    z-index: 1019 !important;
    transition: none;
  }

  @at-root body.view-aside-menu-lg-show & {
    margin-right: 0;
    border-left: 1px solid #c8ced3;
  }

  @at-root body.aside-menu-off-canvas & {
    z-index: 1050;
  }

  .slideout-close-btn {
    position: absolute;
    top: 23px;
    right: $aside-menu-width + 16;
    color: #fff;
    cursor: pointer;
    font-size: 1.8em;
    display: none;

    &:hover {
      color: $color-brand-green-highlight-wash;
    }

    @at-root body.aside-menu-lg-show & {
      display: block;
    }
  }

  .nav-tabs {
    margin: -1rem -1rem 1rem -1rem;

    .nav-item svg {
      margin-left: 0.5rem;
    }
  }

  .slideout-heading {
    background: #f6f6f6;
    padding: 1.3rem 1rem;
    z-index: 1060;

    h2 {
      margin: 0;
      font-size: 1.4rem;
    }
  }

  .slideout-panel {
    padding: 1rem;
    overflow-y: auto;
    height: 92vh;
    z-index: 1060;

    .tab-content {
      border: none;
    }

    table.patientHras {
      th {
        border-top: 0;
        padding-top: 0;

        &:first-child {
          width: 20%;
        }
      }

      td:last-child:not(.react-bs-table-no-data) {
        text-align: right;
      }

      th:not(:first-child):not(:last-child), td:not(:first-child):not(:last-child) {
        text-align: center;
      }

      td.react-bs-table-no-data {
        text-align: left;
      }

      .row-expansion-style {
        text-align: left;

        form {
          margin: 0.75rem 0;
        }
      }
    }

    form {
      h6 {
        font-weight: bold;
      }

      label:not(.form-check-label) {
        font-weight: 600;
      }

      .radio.form-check label {
        cursor: pointer;
      }

      .show-radio-invalid {
        color: $red;

        & ~ .invalid-feedback {
          display: block;
        }
      }

      .Select-menu-outer {
        z-index: 5;
      }
    }
  }
}

.aside-overlay {
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease 0s;
  transform: translate3d(100%, 0px, 0px);
  pointer-events: none;

  @at-root body.aside-menu-lg-show & {
    transition: opacity 0.3s ease 0s;
    transform: none;
    opacity: 1;
    z-index: 1040;
  }
}


// Form modals
.modal-blue {
  .modal-header {
    color: #fff;
    background-color: #224256;
  }

  .modal-content {
    border-color: #224256;
  }

  .modal-footer {
    justify-content: space-between;
  }

  button.close {
    color: #fff;
    opacity: 0.8;
    text-shadow: none;
  }

  form {
    label:not(.form-check-label) {
      font-weight: 600;
    }

    .Select-menu-outer {
      z-index: 5;
    }

    .radio.form-check label {
      cursor: pointer;
    }
  }
}


// Invalid/error styling for `react-select`
.Select.is-invalid {
  & ~ .invalid-feedback {
    display: block;
  }

  .Select-control {
    border-color: $red;
  }

  &.is-open > .Select-control {
    border-color: $red;
  }

  &.is-focused > .Select-control {
    border-color: $red;
    box-shadow: 0 0 0 $input-focus-width rgba($red, .25);
  }

  &.is-focused:not(.is-open) > .Select-control {
    border-color: $form-feedback-invalid-color;
    box-shadow: 0 0 0 $input-focus-width rgba($red, .25);
  }

  &.is-open > .Select-menu-outer {
    border-color: $red;
  }
}


// Miscellaneous & standalone
.error-popover .popover {
  background-color: $danger;
  border-color: $danger;

  .popover-body {
    color: white;
    line-height: 1.4em;
  }

  .arrow::before {
    border-bottom-color: $danger;
  }

  .arrow::after {
    border-bottom-color: $danger;
  }
}

.form-saved-indicator {
  display: inline-block;
  visibility: hidden;
  opacity: 0;
  background: $success;
  color: #fff;
  padding: 3px;
  width: 30px;
  height: 30px;
  text-align: center;
  @include border-radius(26px);

  @at-root .btn + & {
    margin-left: 0.5rem;
  }

  &.show {
    opacity: 1;
    visibility: visible;
    -webkit-animation: popInAnimation 0.25s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
  }

  &.hide {
    -webkit-animation: popOutAnimation 0.15s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
  }
}

.btn-icon svg {
  margin-right: 8px;
}

.btn-icon-right svg {
  margin-left: 8px;
}

.sk-circle {
  .sk-child:before {
    background-color: $color-brand-blue;
  }
}

.spinner-text {
  color: $color-brand-blue;
  font-style: italic;
  text-align: center;
  margin: 0;
}

.profile-form {
  label:not(.form-check-label) {
    font-weight: 600;
  }

  .eligibility-section {
    .eligible-check-submit {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 0.7rem;

      .btn {
        align-self: start;
      }
    }
  }
}

.newuser-form, .forgotpassword-form {
  margin-top: 3rem;

  p:not(.alert) {
    font-size: 1.2rem;
  }

  label:not(.form-check-label) {
    font-weight: 600;
  }
}

.forgot-page {
  .alert-success {
    margin-top: 3rem;
    text-align: left;

    p {
      margin-bottom: 0;
    }
  }
}

.reportdl-popover {
  width: 120px;
  font-size: 0.8rem;
  -webkit-box-shadow: 8px 8px 16px -2px rgba(0,0,0,0.25);
  -moz-box-shadow: 8px 8px 16px -2px rgba(0,0,0,0.25);
  box-shadow: 8px 8px 16px -2px rgba(0,0,0,0.25);

  a {
    display: block;
    text-decoration: none;

    svg:not(.fa-exclamation-triangle) {
      margin-right: 0.66rem;
      color: #ff2116;
    }
  }

  hr {
    margin: 0.4rem 0;
  }

  .fa-exclamation-triangle {
    margin-top: 2px;
    color: $yellow;
  }

  .pdfSpinner {
    display: inline;
    float: right;
    margin-top: 2px;
    padding-top: 0 !important;

    .sk-circle {
      width: 16px;
      height: 16px;
      margin: 0;
    }
  }
}

.row-selected {
  td {
    background: $color-brand-green-highlight-wash;
    border-color: $color-brand-green-highlight;
  }
}

.reports-page {
  .react-bootstrap-table {
    th, td {
      padding: 1rem;
    }

    th:first-child {
      width: 33%;
    }

    td:first-child {
      font-weight: bold;
    }

    th .riskFactor-header {
      svg {
        font-size: 2.15rem;
      }

      span {
        display: block;
        margin: -1.7rem 0 0 2.2rem;
      }

      &.tertiary svg {
        color: #bc1e27;
      }

      &.secondary svg {
        color: #fe9820;
      }

      &.primary svg {
        color: #364c5c;
      }
    }

    a {
      color: $gray-800;
      font-size: 1rem;
      text-decoration: underline;

      &:hover {
        color: #167495;
      }
    }

    td {
      span {
        color: $gray-800;
        font-size: 1rem;
      }
    }

    .riskFactor-info {
      color: $gray-600;
    }
  }

  .back-button {
    margin-bottom: 1rem;
  }

  .intervention-heading {
    svg {
      margin-right: 0.8rem;
    }

    &.tertiary svg {
      color: #bc1e27;
    }

    &.secondary svg {
      color: #fe9820;
    }

    &.primary svg {
      color: #364c5c;
    }

    span {
      font-size: 1.5rem;
      color: $gray-600;
    }
  }
}

.modal-riskInterventions {
  .heading-icon {
    font-size: 2.2rem;
    float: left;

    &.primary {
      color: #364c5c;
    }

    &.secondary {
      color: #fe9820;
    }

    &.tertiary {
      color: #bc1e27;
    }
  }

  h4 {
    line-height: 2.2rem;
    margin-left: 2.5rem;
    color: $color-bluegrey;
  }

  ul {
    margin-bottom: 1.5rem;
    padding-left: 2rem;

    &:last-of-type {
      margin: 0;
    }
  }
}

.billing-page, .reports-page {
  .data-table-header {
    justify-content: right;
    height: 35px;;
  }

  .DateRangePicker {
    margin-right: 0.75rem;
  }

  .DateRangePicker_picker {
    z-index: 10;
  }

  .DateRangePickerInput {
    border-color: $gray-200;
    border-radius: 0.25rem;
  }

  .DateRangePickerInput__withBorder {
    @media print {
      border: 0;
    }
  }

  .DateInput {
    background: transparent;

    &:first-child {
      input {
        padding-left: 12px;
      }
    }

    &:last-child {
      input {
        padding-right: 12px;
      }
    }
  }

  .DateInput_input__small {
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 6px 7px 4px;
    background: transparent;
    padding-top: 6px;
  }

  .DateInput_input__focused {
    border-bottom-color: $color-primary-blue;
  }

  .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background-color: $color-primary-blue;
    border-color: $color-primary-blue;
  }

  .CalendarDay__selected_span {
    background-color: #38bfee;
    border-color: #31b8e7;

    &:active, &:hover {
      background-color: #31b8e7;
    }
  }

  .CalendarDay__hovered_span {
    background-color: #38bfee;
    border-color: #31b8e7;
    color: white;

    &:active, &:hover {
      color: white;
      background-color: #31b8e7;
    }
  }
}

.users-page {
  .react-bootstrap-table {
    td:nth-child(3) {
      word-wrap: break-word;
    }
  }
}

.dashboard-page {
  .card-body {
    z-index: 1;
  }

  .text-value {
    font-size: 3rem;
  }
}

.reporting-date-picker-wrapper {
  float: right;
}

.app-footer {
  @at-root body.view-aside-menu-lg-show & {
    z-index: 1020;
  }
}

.pin-modal {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  max-width: 100%;

  .modal-content {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
  }
}

.app.mobile-awv {
  .app-header {
    height: 80px;
    background: #f0f3f5;
    margin-left: 0;
    padding-left: 30px;

    .logout-button {
      margin-right: 1.2rem;
    }
  }

  .content-wrapper {
    padding-top: 1.5rem;
  }

  .rapidawv-logo {
    height: 50px;
  }
}
